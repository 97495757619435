nce <template>
  <LoadingScreen v-if="!transaction" />
  <template v-else>
      <!-- <div v-if="!isEditLabel" class="text-truncate" style="max-width: 180px;">
        {{ transaction.messageLabel }}
      </div> -->
      <VTooltip :triggers="['hover', 'click']">
        <div class="mb-3 d-flex align-items-center gap-2">
          <input
            v-model="transaction.messageLabel"
            id="messageLabelInput"
            class="form-control w-100"
            :disabled="!transaction.deliveryDetails || transaction.status === 9 || !isEditLabel"
            placeholder="Enter Message Label"
            maxlength="50"
            type="text"
          />
          <label
            v-if="transaction.status !== 5"
            class="m-0 mb-1 text-primary"
            for="messageLabelInput"
            :style="transaction.deliveryDetails ? '' : 'filter: none; opacity: 0.5;'"
            @click="saveMessageLabel"
          >
            <svg v-if="!isEditLabel" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
            <svg v-else width="18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
          </label>
        </div>
        <template v-if="!transaction.deliveryDetails" #popper>
          Please save {{ transaction.deliveryMethod === 'Mail' ? 'delivery address' : transaction.deliveryMethod === 'SMS' ? 'phone number' : 'email address' }} first by tapping blue tick.
        </template>
        <template v-else #popper>
          Enter Message Label
        </template>
      </VTooltip>

    <div class="border border-2 border-primary p-3" style="border-radius: 18px; background-color: #FBFCFF;">
      <MessageBase
        :key="transaction"
        :transaction="transaction"
        @saveTransaction="saveTransaction"
      />
      <div :class="{
        'd-flex mt-3': true,
        'justify-content-end': transaction.deliveryMethod !== 'Mail',
        'justify-content-between': transaction.deliveryMethod === 'Mail'
      }">
        <!-- <button v-if="transaction.status !== 5" class="btn btn-danger" @click="deleteTransaction()">
          Delete
        </button> -->
        <div
          v-if=" transaction.deliveryMethod === 'Mail'"
          class="d-flex flex-column align-items-start"
        >
          <VTooltip :triggers="['hover']">
            <button
              :class="['btn btn-warning', { 'custom-disabled': !isProcessable }]"
              @click="processMail"
            >
              Process Mail
            </button>
            <template #popper>
              Before proceeding you will need to tap PROCESS - then SEND your MAIL message
            </template>
          </VTooltip>
        </div>
        <template v-if="transaction.file && transaction.deliveryMethod === 'Mail'">
          <VTooltip :triggers="['hover']">
            <button class="btn btn-warning" :disabled="isLoadingPreviewMail" data-bs-target="#modalPreview" data-bs-toggle="modal">
              {{ isLoadingPreviewMail ? 'Loading preview...' : 'Preview Mail'}}
            </button>
            <template #popper>
              Tap to PREVIEW how the MAIL message will appear
            </template>
          </VTooltip>
          <ModalPreviewMail :file="transaction.file" />
        </template>
        <div class="btn-group">
          <VTooltip :triggers="['hover']">
            <button
              v-if="!isStuckSending"
              :class="['btn btn-primary', { 'custom-disabled': isSendDisabled || user.isSending }]"
              :data-bs-target="isSendDisabled || user.isSending ? '' : '#modalConfirmSend'"
              :data-bs-toggle="isSendDisabled || user.isSending ? '' : 'modal'"
            >
              Send
            </button>
            <button
              v-if="isStuckSending"
              class="btn btn-danger"
              @click="cancel"
            >
              Cancel
            </button>
            <template #popper>
              <template v-if="transaction.deliveryMethod === 'Mail' && !transaction.file">
                Before proceeding you will need to tap PROCESS - then SEND your MAIL message
              </template>
              <template v-else>
                {{ user.isSending ? 'Still sending another message.' : 'Send your message to your receiver' }}
              </template>
            </template>
          </VTooltip>
        </div>
      </div>
      <div v-if="isDuplicateRecipient" class="mt-3 alert alert-danger py-1 mb-1">
        Message already in flight with this <strong>Template</strong> and <strong>Receiver</strong>.
      </div>
      <div v-if="errorProcessMail" class="alert alert-danger py-1 mt-3 ">
        Error Processing Mail. Please try again
        <small> or <a href="mailto:support@sendamessage.com.au" class="text-decoration-underline">contact support</a>.</small>
      </div>
      <div
        v-if="transaction && transaction.status > 1"
        :class="['messageEdit__status-alert alert mt-3 mb-0 py-1', {
          'alert-warning alert-dismissible': transaction.status === 2 || transaction.status === 4 || typeof isSendableFrequency !== 'boolean',
          'alert-primary alert-dismissible pe-3': transaction.status === 3 || transaction.status === 9,
          'alert-success d-flex justify-content-between align-items-center': transaction.status === 5,
          'alert-danger alert-dismissible': transaction.status === 6 || transaction.status === 7 || transaction.status === 8,
        }]"
        role="alert"
      >
        <div v-if="(typeof isSendableFrequency === 'boolean')">
          <div v-if="transaction.status === 8">
            You are unable to currently send this message to this Receiver as there is a  Message already in flight.
            You need to wait an additional <strong>{{ noOfDaysFrequency }} days</strong> before sending the next message
          </div>
          <div v-else>
            {{ transactionStatus(transaction.status) }}
          </div>
          <span v-if="transaction.status === 6" class="fw-bold">
            {{ transaction.clicksend_status && transaction.clicksend_status.response_msg === 'Invalid inputs.' ? 'Invalid Recipient.' : 'ClickSend Error.' }}
          </span>
          <span v-if="transaction.status === 4 && isStuckSending" class="text-danger">
            <small class="me-2">Message stuck? Click the icon to retry.</small>
            <button
              class="btn-reset"
              :disabled="isSendDisabled || user.isSending"
              :data-bs-target="isSendDisabled || user.isSending ? '' : '#modalConfirmSend'"
              :data-bs-toggle="isSendDisabled || user.isSending ? '' : 'modal'"
            >
              <svg fill="#dc3545" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
            </button>
            <small> or <a href="mailto:support@sendamessage.com.au" class="text-decoration-underline">contact support</a>.</small>
          </span>
          <span v-if="transaction.status === 5 && isSending">Redirecting in {{ countdown }}...</span>
        </div>
        <div v-else class="d-grid d-md-flex justify-content-between align-items-center">
          <div>
            This message template sequence requires *{{Math.round(isSendableFrequency / 24)}}* days to elapse before you can send the next message in the sequence. Please resend this message in <strong>{{ convertHoursToFormattedTime(isSendableFrequency) }}</strong>
            <!-- <div v-if="transaction.reminder"><em>Email reminder will be sent to you once its ready!</em></div> -->
          </div>
          <div class="d-grid gap-2">
            <button class="btn btn-warning" @click="router.push({ name: 'Messages' })" style="white-space: nowrap;">Back to Home</button>
            <button v-if="!transaction.reminder" class="btn btn-secondary" @click="setMessageReminder">Remind Me</button>
          </div>
        </div>

        <VTooltip :triggers="['hover']" class="justify-content-end d-flex" style="flex: 1">
          <button
            v-if="hasNextSequence"
            class="btn btn-success"
            style="white-space: pre"
            @click="createNextSequence(transaction)"
          >
            Next Sequence
          </button>
          <template #popper>
            <div>
              Create Next Sequence Message
            </div>
          </template>
        </VTooltip>
      </div>
      <div v-if="isSaved" class="alert alert-success alert-dismissible mt-3" role="alert">
        <strong>Success!</strong> Changes saved.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" />
      </div>
    </div>
    <div class="mt-2 fs-12 fw-semibold">{{ transaction.status === 5 ? 'Message Sent' : 'Last Saved' }}: {{ formatLastSave(new Date(transaction.updatedAt)) }}</div>
    <ModalConfirmSend :settings="settings" :transaction="transaction" :user="user" @send="send" />
    <div class="pdf-content-wrapper">
      <div id="pdf-content" style="width:570px;" v-html="testContent"></div>
    </div>
  </template>
  <LoadingScreen v-if="isSaving" />
</template>

<script setup>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { createNextSequence } from '@/utils'
import MessageBase from '@/components/MessageEdit/Base.vue'
import ModalConfirmSend from '@/components/MessageEdit/ModalConfirmSend.vue'
import ModalPreviewMail from '@/components/MessageEdit/ModalPreviewMail.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const functions = getFunctions()

const isEditLabel = ref(false)
const isSaved = ref(false)
const isSaving = ref(false)
const isSending = ref(false)
const countdown = ref(5)
const errorProcessMail = ref(false)
const isLoadingPreviewMail = ref(true)
const isDuplicateRecipient = ref(false)
const testContent = ref(null)
const sendCounter = ref(0)

const categories = computed(() => store.state.categories.data)
const user = computed(() => store.state.user.currentUser)
const settings = computed(() => store.state.settings.data)
const templates = computed(() => store.state.templates.data)

// number of days in frequency
const noOfDaysFrequency = computed(() => {
  if (transaction.value.sequenceNumber === 1 || !transaction.value.sequenceNumber) {
    const currentTemplate = templates.value.find(it => it.id === transaction.value.messageTemplateId)

    const date1 = new Date(transaction.value.createdAt)
    const date2 = new Date()

    // Calculate the time difference in milliseconds
    const timeDifference = date2.getTime() - date1.getTime()

    // Convert the time difference to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

    if (daysDifference < currentTemplate.frequency) {
      return Math.round((currentTemplate.frequency - daysDifference) * 100) / 100
    }
  }

  return 0
})

// true = sendable, false = not sendable
const isSendableFrequency = computed(() => {
  if (transaction.value.sequenceNumber === 1) return true

  const transactions = store.state.transactions.data

  const previousTransaction = transactions.find(it => {
    return it.category === transaction.value.category &&
      it.deliveryDetails === transaction.value.deliveryDetails &&
      it.deliveryMethod === transaction.value.deliveryMethod &&
      it.useCase === transaction.value.useCase &&
      it.sequenceNumber === transaction.value.sequenceNumber - 1
  })

  if (!previousTransaction) return true
  const prevTemplate = templates.value.find(it => it.id === previousTransaction.messageTemplateId)

  const date1 = new Date(previousTransaction.sentAt)
  const date2 = new Date()

  // Calculate the time difference in milliseconds
  const timeDifference = date2.getTime() - date1.getTime()

  // Convert the time difference to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  if (daysDifference < prevTemplate.frequency) {
    return (prevTemplate.frequency - daysDifference) * 24
  }

  return true
})

const hasNextSequence = computed(() => {
  const currentTemplate = templates.value.find(it => it.id === transaction.value.messageTemplateId)
  const nextTemplate = templates.value.find(it => {
    return it.categoryId === currentTemplate.categoryId &&
      it.useCaseId === currentTemplate.useCaseId &&
      it.format === currentTemplate.format &&
      it.sequenceNumber === transaction.value.sequenceNumber + 1
  })

  if (!nextTemplate) {
    return false
  }

  // check if transaction is already created for next template
  const transactions = store.state.transactions.data
  const isNextTransactionExist = transactions && transactions.find(it => {
    return it.messageTemplateId === nextTemplate.id &&
      it.deliveryDetails === transaction.value.deliveryDetails
  })

  return (transaction.value.status === 5 && !isSending.value && transaction.value.sequenceNumber < 3) && !isNextTransactionExist
})

const transaction = computed(() => {
  const transactions = store.state.transactions.data

  if (transactions) {
    const trans = transactions.find((it) => it.id === route.params.id)

    if (trans) {
      if (trans.deliveryMethod !== 'SMS' && !trans.header && !trans.footer) {
        return {
          ...trans,
          footer: { content: '', show: false },
          header: { content: '', show: false }
        }
      }

      if (trans.deliveryMethod === 'Mail' && trans.isFileOutdated === undefined) {
        trans.isFileOutdated = true
      }

      trans.sellPrice = setSellPrice(trans)
      trans.buyPrice = setBuyPrice(trans)
      return trans
    }

    return null
  }

  return null
})

const isProcessable = computed(() => {
  console.log('transaction.value.isFileOutda: ', transaction.value.isFileOutdated)

  return checkVars(transaction.value) && checkDeliveryDetails(transaction.value) && transaction.value.status !== 2 && transaction.value.isFileOutdated
})

// function deleteTransaction () {
//   const confirmDelete = confirm ('Are you sure you want to delete this message? This cannot be undone!')
//   if (confirmDelete) {
//     const transactionDelete = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_transactionDelete`)

//     transactionDelete(transaction.value.id).then((res) => {
//       if (res.data) {
//         router.push({ name: 'Messages' }).then(() => router.go())
//       }
//     })
//   }
// }

const isStuckSending = computed(() => {
  if (sendCounter.value > 60) {
    return true
  }

  if (!transaction.value) return false

  const currentTime = new Date()
  const timeDiff = currentTime - new Date(transaction.value.updatedAt)
  if (transaction.value.status === 4 && timeDiff > 60000) {
    return true
  }

  return false
})

// true = not sendable, false = sendable
const isSendDisabled = computed(() => {
  const status = transaction.value.status
  const isSendableFreqBoolean = typeof isSendableFrequency.value === 'boolean'
  const areVarsValid = checkVars(transaction.value)

  if (isStuckSending.value) return false

  if (noOfDaysFrequency.value === 0 && status === 8) {
    return false
  }

  if (transaction.value.deliveryMethod === 'Mail' && !transaction.value.file) return true

  return status === 5 || (status !== 3 && status !== 6) || !isSendableFreqBoolean || !areVarsValid || isSending.value
})

watch(
  transaction,
  (val) => {
    if (val && val.status === 4 && !isStuckSending.value) {
      const counter = setInterval(() => {
        sendCounter.value++

        if (sendCounter.value > 60 || isStuckSending.value) {
          clearInterval(counter)
        }
      }, 1000)
    }
  },
  { immediate: true, deep: true }
)

function formatLastSave (lastSave) {
  const inputDate = new Date(lastSave)

  function addLeadingZero (num) {
    return num < 10 ? '0' + num : num
  }

  var formattedDateString =
  addLeadingZero(inputDate.getDate()) + '/' +
  addLeadingZero(inputDate.getMonth() + 1) + '/' +
  inputDate
    .getFullYear()
    .toString()
    .slice(-2) + ', ' +
  addLeadingZero(inputDate.getHours()) + ':' +
  addLeadingZero(inputDate.getMinutes()) + ':' +
  addLeadingZero(inputDate.getSeconds()) + ' ' +
  (inputDate.getHours() < 12 ? 'AM' : 'PM')

  return formattedDateString
}

async function cancel () {
  sendCounter.value = 0
  isSending.value = false
  const trans = transaction.value

  await store.dispatch('transactions/update', {
    ...trans,
    status: 3,
    updatedAt: new Date().toISOString()
  })
}

function checkVars (trans) {
  // check for unselected vars
  if (trans.paragraphs && trans.paragraphs.length > 0) {
    for (const paragraph of trans.paragraphs) {
      if (paragraph.vars && paragraph.vars.length > 0) {
        for (const it of paragraph.vars) {
          if (!it.selected) {
            return false
          }
        }
      }
    }
  }

  return true
}

function checkDeliveryDetails (trans) {
  if (
    (trans.deliveryMethod === 'Mail' && trans.deliveryDetails && trans.deliveryDetails.full) ||
    (trans.deliveryMethod !== 'Mail' && (trans.deliveryDetails || trans.deliveryDistribution))
  ) {
    return true
  }

  return false
}

function convertHoursToFormattedTime (hours) {
  if (hours >= 24) {
    const days = Math.floor(hours / 24)
    const remainingHours = hours % 24

    const daysText = `${days} day${days !== 1 ? 's' : ''}`
    const formattedHours = remainingHours.toFixed()

    return `${daysText} and ${formattedHours.padStart(2, '0')}:00`
  } else {
    const totalMinutes = hours * 60
    const hoursPart = Math.floor(totalMinutes / 60)
    const minutesPart = Math.floor(totalMinutes % 60)

    const formattedHours = hoursPart.toString().padStart(2, '0')
    const formattedMinutes = minutesPart.toString().padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}`
  }
}

async function processMail () {
  if (!isProcessable.value) return
  errorProcessMail.value = false

  try {
    transaction.value.file = ''
    const mailDate = settings.value.find(it => it.label === 'Mail Date')
    const settingFooter = settings.value.find(it => it.label === 'Mail Footer')
    const settingHeader = settings.value.find(it => it.label === 'Header')
    const settingFontSize = settings.value.find(it => it.label === 'Mail Font Size')
    let footerText = ''

    transaction.value.status = 2
    saveTransaction(transaction.value)

    // console.log('fonts: ', doc.getFontList())

    let mailContent = ''
    const header = transaction.value.header
    const paragraphs = transaction.value.paragraphs.filter(it => !it.isOptional || (it.isOptional && it.isIncluded))
    const footer = transaction.value.footer
    const bodyFont = 'helvetica'
    const bodyFontSize = `${settingFontSize.value}px`
    const bodySpacing = '5px'
    const websites = await getWebsites(transaction.value)

    if (header && header.show) {
      mailContent += `<div id="pdf-content-header" style="font-family: 'times'; text-align: ${settingHeader.alignment || 'center'}">${header.content}</div>`
    }

    mailContent += `<div id="pdf-content-body" style="font-family: ${bodyFont}; font-size: ${bodyFontSize}; padding: 0 24px;">`

    mailContent += '<p>'
    if (mailDate) {
      const d = new Date()
      mailContent += `<strong>Date:</strong> ${d.toDateString()}<br>`
    }

    if (transaction.value.deliveryDetails.isOrganization) {
      mailContent += `<strong>Organization:</strong> ${transaction.value.deliveryDetails.organization}<br>`
    }

    if (transaction.value.deliveryDetails.addressName) {
      mailContent += `<strong>Attention:</strong> ${transaction.value.deliveryDetails.addressName}<br>`
    }

    mailContent += `<strong>Address:</strong> ${transaction.value.deliveryDetails.full}`
    mailContent += '</p>'

    mailContent += '<hr style="margin-top: 0; padding-top: 0;">'

    // mailContent += '<p>'
    if (paragraphs && paragraphs.length > 0) {
      paragraphs.forEach((paragraph) => {
        let text = paragraph.preview || paragraph.text
        // mailContent += text.replace(/<\/p>/g, '<br>').replace(/<p>/g, '')
        mailContent += text.replace(/<p>/g, `<p style="margin-top: ${bodySpacing}; margin-bottom: ${bodySpacing}; padding: 0;">`)

        // if (idx !== paragraphs.length - 1) {
        //   mailContent += ''
        // }
      })
    }
    // mailContent += '</p>'

    if (transaction.value.contactDetails) {
      if (transaction.value.contactDetails.address || transaction.value.contactDetails.email || transaction.value.contactDetails.mobile || transaction.value.contactDetails.name) {
        mailContent += '<hr style="margin-top: 0; padding-top: 0;">'
        mailContent += '<p>'
        mailContent += `
          <strong>Sender's Contact Details:</strong> <br>
          ${transaction.value.contactDetails.name ? 'Name: ' + user.value.name + '<br>' : ''}
          ${transaction.value.contactDetails.mobile ? 'Phone Number: ' + (user.value.country.dial_code || '') + (user.value.phone || '') + '<br>' : ''}
          ${transaction.value.contactDetails.email ? 'Email Address: ' + user.value.email + '<br>' : ''}
          ${transaction.value.contactDetails.address ? 'Home Address: ' + (user.value.address.full || '') + '<br>' : ''}
        `
        mailContent += '</p>'
      }
    }

    if (websites && websites.length > 0) {
      mailContent += '<hr style="margin-top: 0; padding-top: 0;">'
      mailContent += '<p>'
      mailContent += '<strong>Helpful Links:</strong><br>'
      for (const website of websites) {
        if (website.enabled) {
          mailContent += `<a href="${website.name}">` + website.name + '</a><br>'
        }
      }
      mailContent += '</p>'
    }

    // mailContent += '<hr style="margin-top: 0; padding-top: 0;">'
    mailContent += '</div>'

    if (footer && footer.show) {
      footerText = settingFooter.value ? settingFooter.value.split('\\n') : null
    }

    const setContent = () => {
      return new Promise((resolve) => {
        testContent.value = mailContent
        resolve()
      })
    }

    const processParagraphs = () => {
      return new Promise((resolve) => {
        const pdfContentHeader = document.getElementById('pdf-content-header')
        const pdfContentBody = document.getElementById('pdf-content-body')
        const paragraphs = pdfContentBody.getElementsByTagName('p')

        const marginInterval = 820 // Height interval to add margin
        let currentHeight = pdfContentHeader ? pdfContentHeader.offsetHeight : 0 + paragraphs[0].offsetHeight

        for (let i = 0; i < paragraphs.length; i++) {
          if (currentHeight >= marginInterval) {
            paragraphs[i].style.paddingTop = '50px' // Adjust margin as needed
            currentHeight = 0
          }

          currentHeight += paragraphs[i].offsetHeight
        }

        resolve()
      })
    }

    setContent().then(() => {
      processParagraphs().then(() => {
        const doc = new jsPDF('p', 'pt', 'a4')

        doc.html(document.getElementById('pdf-content'), {
          x: 10,
          y: 10,
          callback: (doc) => {
            // add footer text, text is fetch from settings v1 Mail Footer, only accepts 2 lines separated by \n
            if (footerText) {
              let totalPages = doc.internal.getNumberOfPages()

              for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)

                if (i === totalPages) {
                  let nextLine = 10
                  let fontSize = 10

                  footerText.slice(0, 2).reverse().forEach(it => {
                    doc.setFontSize(fontSize)
                    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
                    const text = it.trim()
                    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor
                    const xPos = (doc.internal.pageSize.width - textWidth) / 2
                    doc.text(text, xPos, pageHeight - nextLine)
                    nextLine += 12
                    fontSize += 4
                  })
                }
              }
            }

            // doc.save('test.pdf')
            const pdfBlob = doc.output('dataurlstring')

            const upload = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_uploadMail`)
            upload({ ...transaction.value, file: pdfBlob })
              .then(res => {
                if (res) {
                  transaction.value.pages = doc.getNumberOfPages()
                  transaction.value.file = res.data
                  transaction.value.status = 3
                  transaction.value.isFileOutdated = false
                  saveTransaction(transaction.value)
                }
              })
              .catch((err) => {
                console.log('process mail error: ', err)
                transaction.value.status = 1
                saveTransaction(transaction.value)
                errorProcessMail.value = false
              })
          }
        })
      })
    })
  } catch (err) {
    errorProcessMail.value = true
    console.log('process mail error: ', err)
    transaction.value.status = 1
    saveTransaction(transaction.value)
  }
}

function saveMessageLabel () {
  if (!transaction.value.deliveryDetails || transaction.value.status === 9) return

  isEditLabel.value = !isEditLabel.value

  if (!isEditLabel.value) {
    saveTransaction(transaction.value)
  }
}

async function saveTransaction (trans) {
  isSaving.value = true
  trans.sellPrice = setSellPrice(trans)
  trans.buyPrice = setBuyPrice(trans)

  // set to ready to send if address & vars are set
  const hasDeliveryDetails = checkDeliveryDetails(trans)
  const hasSetVars = checkVars(trans)

  if (
    trans.status === 1 && // only make it 3 if its from incomplete details
    trans.deliveryMethod !== 'Mail' && hasDeliveryDetails && hasSetVars
  ) {
    trans.status = 3
  }

  if (hasDeliveryDetails && !trans.messageLabel) {
    if (trans.deliveryMethod === 'Mail') {
      trans.messageLabel = trans.deliveryDetails.full || ''
    } else {
      trans.messageLabel = trans.deliveryDetails || ''
    }
  }

  await store.dispatch('transactions/update', {
    ...trans,
    updatedAt: new Date().toISOString()
  })

  isSaved.value = true
  isSaving.value = false

  setTimeout(() => {
    isSaved.value = false
  }, 3000)
}

async function getWebsites (trans) {
  if (trans.deliveryMethod === 'SMS') return
  const currentTemplate = templates.value.find(it => it.id === trans.messageTemplateId)
  const category = categories.value.find(cat => cat.id === currentTemplate.categoryId)
  const useCase = category.useCases.find(it => it.id === currentTemplate.useCaseId)
  return useCase.websites || null
}

async function send (isSinglePayment) {
  // user.isSending to true before everything
  if (!isSinglePayment) {
    store.dispatch('user/updateProfile', {
      ...user.value,
      isSending: true
    })
  }

  if (isSendDisabled.value) return

  const trans = transaction.value

  isSending.value = true
  isDuplicateRecipient.value = false

  if (trans.deliveryMethod !== 'SMS') {
    trans.websites = await getWebsites(trans)
  }

  if (trans.schedule) {
    const saveScheduled = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_saveScheduled`)
    saveScheduled(trans).then(res => {
      isSending.value = false
      if (res.data) {
        saveTransaction({ ...trans, status: 9 })
      } else {
        saveTransaction({ ...trans, status: 6 })
        isSending.value = false
      }
    })
  } else {
    saveTransaction({ ...trans, status: 4 })

    const send = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_send`)
    send(trans).then(res => {
      if (res.data) {
        setCountDown()
      } else {
        isSending.value = false
      }
    })
  }
}

function setCountDown () {
  const timer = setInterval(() => {
    countdown.value -= 1

    if (countdown.value <= 0) {
      clearInterval(timer)
      router.push({ name: 'Messages' })
    }
  }, 1000)
}

function setBuyPrice (trans) {
  // set buy price here
  const costSms = settings.value.find(it => it.id === '-NBsJpGFj3fhq-opX_h-')
  const costEmail = settings.value.find(it => it.id === '-NBsJs8yNh0I9a1o8S3O')
  const costMail = settings.value.find(it => it.id === '-NBsJva79Xynm6zSqdDw')
  const costMailColour = settings.value.find(it => it.id === '-NQRRTCKKD3kC05GHaBS')
  const costMailPriority = settings.value.find(it => it.id === '-NR4TJpvqv5S_O6g40dD')
  const costMailPerBlack = settings.value.find(it => it.id === '-NS5WiF6fucoRuQhXUID')
  const costMailPerColour = settings.value.find(it => it.id === '-NSkCg4StGDH6aP1Stud')
  const costLargeLetterSurcharge = settings.value.find(it => it.id === '-NSkG2YD4ovxaLedPtfM')
  const costLargeLetterSurchargeSheet = settings.value.find(it => it.id === '-NSkG5pNAR3v5_CExEMd')

  if (trans.deliveryMethod !== 'Mail') {
    const costValue = trans.deliveryMethod === 'SMS' ? parseFloat(costSms.value) : parseFloat(costEmail.value)

    if (trans.deliveryDistribution) {
      const numberOfRecipients = trans.deliveryDistribution.recipients.length
      return costValue * numberOfRecipients
    }

    return Math.round(costValue * 1000) / 1000
  }

  /* Mail */
  let mailBuy = 0

  // initial buy price
  if (trans.colour) {
    mailBuy = parseFloat(costMailColour.value)
  } else {
    mailBuy = parseFloat(costMail.value)
  }

  // priority cost
  if (trans.priority) {
    mailBuy += parseFloat(costMailPriority.value)
  }

  // more than 1 pages
  if (trans.pages && trans.pages > 1) {
    if (trans.colour) {
      mailBuy += parseFloat(costMailPerColour.value) * (trans.pages - 1)
    } else {
      mailBuy += parseFloat(costMailPerBlack.value) * (trans.pages - 1)
    }
  }

  // 5 or more pages
  if (trans.pages && trans.pages >= 5) {
    mailBuy += parseFloat(costLargeLetterSurcharge.value) + (parseFloat(costLargeLetterSurchargeSheet.value) * trans.pages)
  }

  return Math.round(mailBuy * 1000) / 1000
}

function setSellPrice (trans) {
  // set cost here
  const sellSms = settings.value.find(it => it.id === '-NBsJqqTgoO0XxwORK_9')
  const sellEmail = settings.value.find(it => it.id === '-NBsJtcjo50MLbRzZFHT')
  const sellMail = settings.value.find(it => it.id === '-NBsJwo00p0aTgv-p1qR')
  const sellMailColour = settings.value.find(it => it.id === '-NQRS4R3tF_h_-3_ZJaU')
  const sellMailPriority = settings.value.find(it => it.id === '-NR4TjXzcsRW0jz7eWqY')
  const costMailPerBlack = settings.value.find(it => it.id === '-NS5WiF6fucoRuQhXUID')
  const costMailPerColour = settings.value.find(it => it.id === '-NSkCg4StGDH6aP1Stud')
  const costLargeLetterSurcharge = settings.value.find(it => it.id === '-NSkG2YD4ovxaLedPtfM')
  const costLargeLetterSurchargeSheet = settings.value.find(it => it.id === '-NSkG5pNAR3v5_CExEMd')

  if (trans.deliveryMethod !== 'Mail') {
    const sellValue = trans.deliveryMethod === 'SMS' ? parseFloat(sellSms.value) : parseFloat(sellEmail.value)

    if (trans.deliveryDistribution) {
      const numberOfRecipients = trans.deliveryDistribution.recipients.length
      const gstDL = Math.round(((sellValue * numberOfRecipients) * 0.091) * 100) / 100
      const initialDL = Math.round((sellValue * numberOfRecipients) * 100) / 100
      const totalDL = Math.round((gstDL + initialDL) * 100) / 100

      return {
        gst: gstDL,
        initial: initialDL,
        total: totalDL
      }
    }

    const gst = Math.round((sellValue * 0.091) * 100) / 100
    const initial = Math.round(sellValue * 100) / 100
    const total = Math.round((gst + initial) * 100) / 100

    return {
      gst,
      initial,
      total
    }
  }

  /* Mail */
  let mailCost = 0

  // initial cost
  if (trans.colour) {
    mailCost = parseFloat(sellMailColour.value)
  } else {
    mailCost = parseFloat(sellMail.value)
  }

  // priority cost
  if (trans.priority) {
    mailCost += parseFloat(sellMailPriority.value)
  }

  // more than 1 pages
  if (trans.pages && trans.pages > 1) {
    if (trans.colour) {
      mailCost += parseFloat(costMailPerColour.value) * (trans.pages - 1)
    } else {
      mailCost += parseFloat(costMailPerBlack.value) * (trans.pages - 1)
    }
  }

  // 5 or more pages
  if (trans.pages && trans.pages >= 5) {
    mailCost += parseFloat(costLargeLetterSurcharge.value) + (parseFloat(costLargeLetterSurchargeSheet.value) * trans.pages)
  }

  const gstMail = Math.round((mailCost * 0.091) * 100) / 100
  const initialMail = Math.round(mailCost * 100) / 100
  const totalMail = Math.round((gstMail + initialMail) * 100) / 100

  return {
    gst: gstMail,
    initial: initialMail,
    total: totalMail
  }
}

async function setMessageReminder () {
  const now = new Date()
  const millisecondsInHour = 60 * 60 * 1000
  const targetDate = new Date(now.getTime() + isSendableFrequency.value * millisecondsInHour)

  await store.dispatch('transactions/update', {
    ...transaction.value,
    updatedAt: new Date().toISOString(),
    reminder: new Date(targetDate).toISOString()
  })
}

function transactionStatus (status) {
  switch (status) {
    case 2: return 'Processing Message...'
    case 3: return 'Ready To Send'
    case 4: return 'Sending Message...'
    case 5: return 'This message has now been sent to the receiver and to your message history. You cannot make any changes to it.'
    case 6: return 'Sending Failed! Try again.'
    case 7: return 'Sending Failed! SPAM!'
    case 9: return 'In queue to be sent to receiver'
  }
}

onMounted(() => {
  setTimeout(() => {
    isLoadingPreviewMail.value = false
  }, 1000)
})
</script>

<style lang="scss">
.pdf-content-wrapper {
  position: absolute;
  top: -9999px;
}

#pdf-content {
  position: relative;
  z-index: -9999;
  p {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .messageEdit__status-alert {
    flex-wrap: wrap;
  }
}
</style>
